<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <img src="img/logo.png" class="c-avatar-img" />
      <CIcon
        class="c-sidebar-brand-minimized"
        name="logo"
        size="custom-size"
        :height="35"
        viewBox="0 0 110 134"
      />
    </CSidebarBrand>
    <CRow>
      <CCol style="padding-left:25px;" col="6"><span>$ {{ total }}</span></CCol>
      <CCol col="5"><span><CBadge color="primary ms-auto">Ventas de hoy</CBadge></span></CCol>
    </CRow>
    <CRow>
      <CCol style="padding-left:25px;" col="6"><span>$ {{ pending }}</span></CCol>
      <CCol col="5"><span><CBadge color="primary ms-auto">No completadas</CBadge></span></CCol>
    </CRow>
    <!--<CRow v-if="hasProfit">
      <CCol style="padding-left:25px;" col="6"><span>$ {{ accumulativeSales }}</span></CCol>
      <CCol col="5"><span><CBadge color="primary ms-auto">Ventas x liquidar</CBadge></span></CCol>
    </CRow>-->
    <CRow v-if="hasProfit">
      <CCol style="padding-left:25px;" col="6"><span>$ {{ accumulativeProfit }}</span></CCol>
      <CCol col="5"><span><CBadge color="primary ms-auto">Ganancia x liquidar </CBadge></span></CCol>
    </CRow>
    <CRow v-if="showBadges" class="px-3">
        <CHeaderNavLink style="padding: 5px 90px;" to="/reports">
          <radial-progress-bar
            :diameter="75"
            :completed-steps="parseFloat(selectedBadge?(currentAmount<selectedBadge.value?currentAmount:selectedBadge.value):0)"
            :total-steps="parseFloat(selectedBadge?selectedBadge.value:0)"
            startColor="#0094A1"
            endColor="#00454E"
          />
        </CHeaderNavLink>
    </CRow>
    <CRow v-if="showBadges" class="px-3">
      <CHeaderNavLink to="/reports">
        <p style="color: white;">${{ amountForNextMedal }} más para tu próxima medalla</p>
      </CHeaderNavLink>
    </CRow>
    <CRenderFunction flat :content-to-render="nav" />
  </CSidebar>
</template>

<script>
import DashboardResource from "@/api/dashboard";
import RadialProgressBar from 'vue-radial-progress'
import Resource from "@/api/resource";

const dashboardResource = new DashboardResource();
const resource = new Resource("dashboard");

export default {
  name: "TheSidebar",
  components: { RadialProgressBar, },
  //nav,
  data() {
    return {
      total: 0,
      pending: 0,
      profit: 0,
      toprofit: 0,
      accumulativeSales: 0,
      accumulativeProfit: 0,
      nav: [],
      hasProfit: true,
      currentAmount: 0,
      selectedBadge: null,
      showBadges: false,
    };
  },
  mounted() {
    this.nav = this.$store.state.user.menu;
    const notProfit = ['Mayorista', 'Distribuidor'];
    if( notProfit.includes(this.$store.state.user.role) ){
      this.hasProfit = true;
    }else{
      this.hasProfit = false;
    }
    if(this.$store.state.user.role){
      this.loadData();
      setInterval(() => {
        this.loadData();
      }, (1000 * 180));
    }
  },
  computed: {
    show() {
      return this.$store.state.app.sidebarShow;
    },
    minimize() {
      return this.$store.state.app.sidebarMinimize;
    },
    amountForNextMedal() {
      return parseFloat(this.selectedBadge?this.selectedBadge.value:0) - parseFloat(this.selectedBadge?(this.currentAmount<this.selectedBadge.value?this.currentAmount:this.selectedBadge.value):0);
    },
  },
  methods: {
    async loadData() {
      await dashboardResource.totalSoldDaySons().then((response) => {
        if (response.data.data.profit) {
          this.total = parseFloat(response.data.data.profit).toFixed(2);
        }
      });
      await dashboardResource.totalPendingSoldDaySons().then((response) => {
        if (response.data.data.profit) {
          this.pending = parseFloat(response.data.data.profit).toFixed(2);
        }
      });
      await dashboardResource.totalDataReports().then(response => {
        this.accumulativeSales = parseFloat(response.data.accumulativeSales).toFixed(2);
        this.accumulativeProfit = parseFloat(response.data.accumulativeSalesProfit).toFixed(2);
      });
      await resource.get('salesByMonth').then(response => {
        this.currentAmount = parseFloat(response.data[0].sum).toFixed(2);
        this.currentAmount = isNaN(this.currentAmount) ? 0 : this.currentAmount;
        if(this.$store.state.user.role == "Distribuidor"){
          this.showBadges=true;
        }
      }).catch(error => {
        this.showBadges=false;
      }).finally(() => {

      });
      await resource.get('nextAwards').then(response => {
        this.badges = response.data.data;
        this.selectBadge();
      }).catch(error => {
        this.showBadges=false;
      }).finally(() => {

      });
    },
    selectBadge(badge) {
      this.selectedBadge = badge ? badge : this.findClosestBadge();
      this.selectedBadge.value = parseFloat(this.selectedBadge.value);
    },
    findClosestBadge() {
      let closestBadge = null;
      let closestDifference = Infinity;

      for (const badge of this.badges) {
        const badgeValue = badge.value;
        const difference = Math.abs(this.currentAmount - badgeValue);

        if (difference < closestDifference) {
          closestBadge = badge;
          closestDifference = difference;
        }
      }
      return closestBadge;
    },
  },
};
</script>
