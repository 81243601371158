<template>
  <CDropdown
    inNav
    class="c-header-nav-items m-4"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
          <font-awesome-icon icon="bell"/>
          <CBadge color="info" position="top-end" >
            {{ cartProducts.length }}
          </CBadge>
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>Norificaciones</strong>
    </CDropdownHeader>
    <CDropdownItem>
      <p>Sin notificaciones</p>
    </CDropdownItem>
    
  </CDropdown>
</template>

<script>
export default {
  name: 'TheHeaderDropdownNotification',
  data () {
    return { 
      products: []
    }
  },
  mounted() {
    this.products = this.$store.state.commerce.cart
  },
  computed: {
    cartProducts () {
      return []
    },
    subtotal () {
        var subtotal = 0;
        this.products.map(function(product){
            subtotal += product.quantity*product.Price
        });
        return subtotal; 
    },
    total (){
        var total = 0;
        this.products.map(function(product){
            total += product.quantity*product.Price
        });
        return total*1.15; 
    }
  },
  methods:{
    formatPrice(value) {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
  }
}
</script>

<style scoped>
.prices{
    right: 0;
    position: absolute;
    margin-right: 10px;
}
.icon {
  margin-right: 0.3rem;
}
</style>