<template>
  <div class="c-app">
    <TheSidebar/>
    <CWrapper>
      <TheHeader/>
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
      <TheFooter/>
    </CWrapper>
  </div>
</template>

<script>
import TheSidebar from './TheSidebar'
import TheHeader from './TheHeader'
import TheFooter from './TheFooter'
import UserResource from '@/api/user'

const userResource = new UserResource();

export default {
  name: 'TheContainer',
  async mounted(){
    if(!this.$store.state.user.token || this.$store.state.user.token===null){
      this.$store.commit('setMenu', null);
      this.$store.commit('setRole', null);
      this.$store.commit('setName', null);
      this.$router.push('/pages/login').catch(() => {});
    }
  },
  components: {
    TheSidebar,
    TheHeader,
    TheFooter
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
