<template>
  <CFooter :fixed="false">
    <div>
      <a href="https://bsmart.ec" target="_blank">bSmart</a>
      <span class="ml-1">&copy; {{new Date().getFullYear()}}</span>
    </div>
    <div class="mfs-auto">
      <span class="mr-1" target="_blank">Developed by</span>
      <a href="https://netsoft.com.ec">Netsoft Consulting</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
