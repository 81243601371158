<template>
  <CDropdown
    inNav
    class="c-header-nav-items m-6"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
          <font-awesome-icon icon="award" size="lg" color="#1A454E" />
          <CBadge color="info" position="top-end">
            {{ data.length }}
          </CBadge>
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>Medallas</strong>
    </CDropdownHeader>
    <CDropdownItem v-for="(value, key) in data" :key="key">
      <p>{{ value.name }}</p>
    </CDropdownItem>
    <CDropdownItem v-if="data.length == 0">
      <p>Sin medalllas de momento</p>
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import Resource from "@/api/resource";

const resource = new Resource("dashboard");

export default {
  name: "TheHeaderDropdownCart",
  data() {
    return {
      isLoading: false,
      data: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      try {
        const result = await resource.get("badgesByUser");
        if (result.status == 200) {
          const json = result.data;
          this.data = json.data;
        } else {
          console.log(result);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.prices {
  right: 0;
  position: absolute;
  margin-right: 10px;
}
.icon {
  margin-right: 0.3rem;
}
</style>
