<template>
  <CDropdown
    inNav
    class="c-header-nav-items m-6"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
          <font-awesome-icon icon="shopping-cart" size="lg" color="#1A454E" bounce />
          <CBadge color="danger" position="top-end" >
            {{ cartProducts.length }}
          </CBadge>
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>Carrito</strong>
    </CDropdownHeader>
    <div style="max-height: 15em; overflow: auto;">
      <CDropdownItem v-for="(product, index) in products" :key="'head'+product.ItemCode">
        <div style="width: 100%">
            <p><strong>{{ product.ItemName }}</strong></p>
            <p> 
              {{ product.quantity }} x ${{ product.Price }} = ${{ formatPrice(product.Price*product.quantity) }}
              <span style="color: red; float: right; font-size: larger;" @click="(event) => $store.commit('delete', index)">
                <font-awesome-icon icon="trash" />
              </span>
            </p>
        </div>
      </CDropdownItem>
    </div>
    <CDropdownDivider/>
    <CDropdownItem>
      <p>Subtotal <span class="prices">${{ formatPrice(subtotal) }}</span></p>
    </CDropdownItem>
    <CDropdownItem>
      <p>IVA <span class="prices">${{ formatPrice(total - subtotal) }}</span></p>
    </CDropdownItem>
    <CDropdownItem>
      <p>Total <span class="prices">${{ formatPrice(total) }}</span></p>
    </CDropdownItem>
    <CDropdownDivider/>
    <CDropdownItem @click="() => continueRouter()">
      <CButton color="info">
      <font-awesome-icon icon="cash-register" class="icon" />  ¿Esta correcto?, Continuar
      </CButton>
    </CDropdownItem>
    
  </CDropdown>
</template>

<script>
export default {
  name: 'TheHeaderDropdownCart',
  data () {
    return { 
      products: []
    }
  },
  mounted() {
    this.products = this.$store.state.commerce.cart
  },
  computed: {
    cartProducts () {
      return this.$store.state.commerce.cart 
    },
    subtotal () {
        var subtotal = 0;
        this.products.map(function(product){
            subtotal += product.quantity*product.Price
        });
        return subtotal; 
    },
    total (){
        var total = 0;
        this.products.map(function(product){
            total += product.quantity*product.Price
        });
        return total*1.15; 
    }
  },
  methods:{
    continueRouter(){
      const inventory = this.$store.state.commerce.cart.filter(function(item){
        return item.ItemCode.includes('DST'); 
      });
      if(inventory.length > 0){
        this.$router.push('/commerceInventory/cart');
      }else{
        this.$router.push('/commerce/cart');
      }
    },
    formatPrice(value) {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
  }
}
</script>

<style scoped>
.prices{
    right: 0;
    position: absolute;
    margin-right: 10px;
}
.icon {
  margin-right: 0.3rem;
}
</style>