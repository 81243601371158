import Resource from '@/api/resource';
import request from '@/utils/request';

class LiquidationResource extends Resource {
  constructor() {
    super('dashboard');
  }

  salesByUser() {
    return request({
      url: '/dashboard/salesByUser',
      method: 'get',
    });
  }

  pendingSales() {
    return request({
      url: '/dashboard/pendingSales',
      method: 'get',
    });
  }

  topSellingProducts() {
    return request({
      url: '/dashboard/topSellingProducts',
      method: 'get',
    });
  }

  topSalesByUser() {
    return request({
      url: '/dashboard/topSalesByUser',
      method: 'get',
    });
  }

  badgesByUser() {
    return request({
      url: '/dashboard/badgesByUser',
      method: 'get',
    });
  }

  totalSoldDaySons() {
    return request({
      url: '/dashboard/totalSoldDaySons',
      method: 'get',
    });
  }

  totalPendingSoldDaySons() {
    return request({
      url: '/dashboard/totalPendingSoldDaySons',
      method: 'get',
    });
  }
  totalSoldDaySonsProfit() {
    return request({
      url: '/dashboard/totalSoldDaySons',
      method: 'get',
    });
  }

  totalPendingSoldDaySonsProfit() {
    return request({
      url: '/dashboard/totalPendingSoldDaySons',
      method: 'get',
    });
  }

  totalDataReports() {
    return request({
      url: '/dashboard/salesReport3?tree=1',
      method: 'get',
    });
  }

  getReport(data) {
    return request({
      url: '/' + this.uri + '/' + data,
      method: 'get',
      responseType: 'blob',
    });
  }

}

export { LiquidationResource as default };
